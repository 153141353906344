<script>
  import { getContext, onDestroy } from 'svelte';
  import { t } from '../../../i18n';
  import VerticalProgress from '../VerticalProgress/VerticalProgress.svelte';
  import D3PedalMap_throttle from '../D3PedalMap/D3PedalMap_throttle.svelte';

  let message = getContext('WSC-message');
  let pedalMap = getContext('WSC-pedalMap');
  let invertedMap = getContext('WSC-invertedMap');
  let smoothMap = getContext('WSC-smoothMap');

  let progress = 0;
  let pedalMapNumbers = [0, 20, 40, 60, 80, 100];
  let smooth = false;
  let inverted = false;

  const updateContext = (e) => {
    pedalMapNumbers[e.target.name] = parseInt(e.target.value);
    pedalMap.update((existing) => {
      return { ...existing, ...{ throttleMap: pedalMapNumbers } };
    });
  };

  const checkIfMatchCurveList = (throttleMap) => {
    const curve = JSON.stringify(throttleMap);
    if (curve === JSON.stringify(linearMap)) {
      return 'linearMap';
    }
    if (curve === JSON.stringify(slowCurveMap)) {
      return 'slowCurveMap';
    }
    if (curve === JSON.stringify(verySlowCurveMap)) {
      return 'verySlowCurveMap';
    }
    if (curve === JSON.stringify(fastCurveMap)) {
      return 'fastCurveMap';
    }
    if (curve === JSON.stringify(veryFastCurveMap)) {
      return 'veryFastCurveMap';
    }
    if (curve === JSON.stringify(sCurveFastSlowMap)) {
      return 'sCurveFastSlowMap';
    }
    if (curve === JSON.stringify(sCurveSlowFastMap)) {
      return 'sCurveSlowFastMap';
    }
    return '';
  };

  const updateSmooth = (e) => {
    smoothMap.update((existing) => {
      return { ...existing, ...{ throttleSmooth: e.target.checked ? '1' : '0' } };
    });
  };
  const updateInverted = (e) => {
    invertedMap.update((existing) => {
      return { ...existing, ...{ throttleInverted: e.target.checked ? '1' : '0' } };
    });
  };

  const updateMapNumbers = (e) => {
    const selectedCurve = getMatchingCurve(e.target.value);
    pedalMap.update((existing) => {
      return { ...existing, ...{ throttleMap: selectedCurve } };
    });
  };
  const getMatchingCurve = (selectedValue) => {
    if (selectedValue === 'linearMap') {
      return linearMap.concat();
    }
    if (selectedValue === 'slowCurveMap') {
      return slowCurveMap.concat();
    }
    if (selectedValue === 'verySlowCurveMap') {
      return verySlowCurveMap.concat();
    }
    if (selectedValue === 'fastCurveMap') {
      return fastCurveMap.concat();
    }
    if (selectedValue === 'veryFastCurveMap') {
      return veryFastCurveMap.concat();
    }
    if (selectedValue === 'sCurveFastSlowMap') {
      return sCurveFastSlowMap.concat();
    }
    if (selectedValue === 'sCurveSlowFastMap') {
      return sCurveSlowFastMap.concat();
    }
  };

  let curves = 'linearMap';
  const linearMap = [0, 20, 40, 60, 80, 100];
  const slowCurveMap = [0, 9, 21, 39, 63, 100];
  const verySlowCurveMap = [0, 4, 11, 25, 48, 100];
  const fastCurveMap = [0, 37, 61, 79, 91, 100];
  const veryFastCurveMap = [0, 52, 75, 89, 96, 100];
  const sCurveFastSlowMap = [0, 60, 75, 80, 85, 100];
  const sCurveSlowFastMap = [0, 31, 46, 54, 69, 100];

  const unsubscribeMessage = message.subscribe({
    next: (msg) => {
      progress = msg.throttle.after || 0;
    },
    complete: () => {
      console.log('[readLoop] DONE');
    }
  });

  smoothMap.subscribe((value) => {
    if (value) {
      smooth = value.throttleSmooth === '1';
    }
  });

  invertedMap.subscribe((value) => {
    if (value) {
      inverted = value.throttleInverted === '1';
    }
  });

  pedalMap.subscribe((value) => {
    if (JSON.stringify(value) !== '{}') {
      const { throttleMap } = value;
      pedalMapNumbers = throttleMap;
      curves = checkIfMatchCurveList(throttleMap);
    }
  });

  onDestroy(() => {
    unsubscribeMessage.unsubscribe();
  });
</script>

<div style="display:block; text-align:center; padding:30px">
  <strong style="font-size: 20px">{$t('throttle')}</strong>
  <div style="display:flex; justify-content:space-around; margin-top:20px; ">
    <div class="form-check form-switch" style="display:flex; justify-content:center; flex-direction:column ; padding:0;">
      <input class="form-check-input" type="checkbox" role="switch" on:input={(e) => updateSmooth(e)} checked={smooth} style="align-self:center" />
      <label style="width:100%" class="form-check-label" for="flexSwitchCheckDefault">{$t('configuration.check.smooth')}</label>
    </div>
    <div class="form-check form-switch" style="display:flex; justify-content:center; flex-direction:column; padding:0;">
      <input class="form-check-input" type="checkbox" role="switch" on:input={(e) => updateInverted(e)} checked={inverted} style="align-self:center" />
      <label class="form-check-label" for="flexSwitchCheckDefault">{$t('configuration.check.inverted')}</label>
    </div>
  </div>
  <div style="display: flex;">
    <div style="display: inline-block;  vertical-align: top; margin-top:20px">
      <div style="display: inline-block;">
        <div class="input-group mb-3">
          <div style="width:70px" class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">100%</span>
          </div>
          <input
            describedby="basic-addon1"
            class="form-control"
            min="0"
            max="100"
            type="number"
            on:input={(e) => updateContext(e)}
            name="5"
            value={pedalMapNumbers[5]}
          />
        </div>
        <div class="input-group mb-3">
          <div style="width:70px" class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">80%</span>
          </div>
          <input
            describedby="basic-addon1"
            class="form-control"
            min="0"
            max="100"
            type="number"
            on:input={(e) => updateContext(e)}
            name="4"
            value={pedalMapNumbers[4]}
          />
        </div>
        <div class="input-group mb-3">
          <div style="width:70px" class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">60%</span>
          </div>
          <input
            describedby="basic-addon1"
            class="form-control"
            min="0"
            max="100"
            type="number"
            on:input={(e) => updateContext(e)}
            name="3"
            value={pedalMapNumbers[3]}
          />
        </div>
        <div class="input-group mb-3">
          <div style="width:70px" class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">40%</span>
          </div>
          <input
            describedby="basic-addon1"
            class="form-control"
            min="0"
            max="100"
            type="number"
            on:input={(e) => updateContext(e)}
            name="2"
            value={pedalMapNumbers[2]}
          />
        </div>
        <div class="input-group mb-3">
          <div style="width:70px" class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">20%</span>
          </div>
          <input
            describedby="basic-addon1"
            class="form-control"
            min="0"
            max="100"
            type="number"
            on:input={(e) => updateContext(e)}
            name="1"
            value={pedalMapNumbers[1]}
          />
        </div>
        <div class="input-group mb-3">
          <div style="width:70px" class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">0%</span>
          </div>
          <input
            describedby="basic-addon1"
            class="form-control"
            min="0"
            max="100"
            type="number"
            on:input={(e) => updateContext(e)}
            name="0"
            value={pedalMapNumbers[0]}
          />
        </div>
      </div>
    </div>
    <div style="display: inline-block;  vertical-align: top; margin-top:20px">
      <D3PedalMap_throttle />
      <div style="margin-left: 20px; margin-right: 20px; margin-top: 12px">
        <select class="form-select" style="height: 40px;" name="curves" value={curves} on:input={(e) => updateMapNumbers(e)}>
          <option value="">{$t('configuration.curves.customCurve')}</option>
          <option value="linearMap">{$t('configuration.curves.linear')}</option>
          <option value="slowCurveMap">{$t('configuration.curves.slowCurve')}</option>
          <option value="verySlowCurveMap">{$t('configuration.curves.verySlowCurve')}</option>
          <option value="fastCurveMap">{$t('configuration.curves.fastCurve')}</option>
          <option value="veryFastCurveMap">{$t('configuration.curves.veryFastCurve')}</option>
          <option value="sCurveFastSlowMap">{$t('configuration.curves.sCurveFastSlow')}</option>
          <option value="sCurveSlowFastMap">{$t('configuration.curves.sCurveSlowFast')}</option>
        </select>
      </div>
    </div>
    <div style="display: inline-block; vertical-align: top;">
      <VerticalProgress {progress} height="400" />
    </div>
  </div>
</div>

<style>
  label {
    text-align: center;
  }
  input {
    width: 70%;
    margin: 3px;
    text-align: center;
  }
  select {
    width: 100%;
    text-align: center;
    height: 30px;
  }
</style>
