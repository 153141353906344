<script>
  import { t, locale, locales } from './i18n';
  import WebSerialContext from './Services/WebSerialContext.svelte';
  import Buttons from './Buttons/Buttons.svelte';
  import { Tab, TabList, TabPanel, Tabs } from './Tabs/tabs';

  import Overlay from './Overlay/Overlay.svelte';
  import Pedals from './Pedals/Pedals.svelte';
  import Calibration from './Calibration/Calibration.svelte';
  import Logging from './Logging/Logging.svelte';
  import About from './About/About.svelte';
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap" rel="stylesheet" />
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" />
</svelte:head>

<div style="width: 100%; height: 100%; margin: 0 auto;">
  <WebSerialContext>
    <div style="height:100vh; position: relative;">
      <Overlay>
        <section>
          <div style="display: flex; justify-content:right;">
            <select bind:value={$locale}>
              {#each locales as l}
                <option value={l}>{l}</option>
              {/each}
            </select>
          </div>
          <div style="display: flex; justify-content:center; margin: 20px">
            <img src="https://www.kimbodev.com.ar/img/logo.webp" style="width: 180px; height:80px;" alt="Not Found" />
          </div>
          <p style="text-align: center; font-size: 18px;">
            {@html $t('overlay.message')}
          </p>
        </section>
        <Buttons />
      </Overlay>
      <div
        style="position: absolute;
    top: 0;
    left: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;"
      >
        <Tabs>
          <TabList>
            <Tab>{$t('tabs.pedals')}</Tab>
            <Tab>{$t('tabs.calibration')}</Tab>
            <!--
          <Tab>Logging</Tab>
          <Tab>About</Tab>
          -->
          </TabList>

          <TabPanel>
            <Pedals />
          </TabPanel>

          <TabPanel>
            <Calibration />
          </TabPanel>

          <TabPanel>
            <Logging />
          </TabPanel>

          <TabPanel>
            <About />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  </WebSerialContext>
</div>

<style>
  * {
    font-family: 'Roboto', sans-serif;
  }
  :global(body) {
    margin: 0px;
    height: 100vh;
  }
  :global(#app) {
    height: 100vh;
  }

  :global(input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button) {
    opacity: 1;
  }

  :global(input) {
    margin: 0px !important;
  }

  :global(select.form-control:not([size]):not([multiple])) {
    height: auto !important;
  }
</style>
