<script>
  import { getContext, onDestroy } from 'svelte';
  import { t } from '../../../i18n';
  import CalibrationOverlay from './CalibrationOverlay.svelte';

  let message = getContext('WSC-message');
  let calibrationMap = getContext('WSC-calibrationMap');
  let bitsMap = getContext('WSC-bitsMap');
  let calibrationInProgress = false;

  let calibrationMapNumbers = [0, 1023, 0, 1023];
  let bitsMapNumbers = [1023, 1023];

  const updateContext = (e) => {
    calibrationMapNumbers[e.target.name] = parseInt(e.target.value);
    calibrationMap.update((existing) => {
      return { ...existing, ...{ clutchCalibration: calibrationMapNumbers } };
    });
  };

  const calibrateStart = () => {
    calibrationInProgress = true;
    calibrationMapNumbers[0] = null;
    calibrationMapNumbers[1] = null;
  };
  const calibrateDone = () => {
    calibrationInProgress = false;
    calibrationMap.update((existing) => {
      return { ...existing, ...{ clutchCalibration: calibrationMapNumbers } };
    });
  };

  calibrationMap.subscribe((value) => {
    const { clutchCalibration } = value;
    calibrationMapNumbers = clutchCalibration;
  });

  bitsMap.subscribe((value) => {
    const { clutchBits } = value;
    bitsMapNumbers = clutchBits;
  });

  const unsubscribeMessage = message.subscribe({
    next: (msg) => {
      if (calibrationInProgress) {
        if (calibrationMapNumbers[0] === null) {
          calibrationMapNumbers[0] = msg.clutch.raw;
        }
        if (msg.clutch.raw < calibrationMapNumbers[0]) {
          calibrationMapNumbers[0] = msg.clutch.raw;
        }
        if (calibrationMapNumbers[1] === null) {
          calibrationMapNumbers[1] = msg.clutch.raw;
        }
        if (msg.clutch.raw > calibrationMapNumbers[1]) {
          calibrationMapNumbers[1] = msg.clutch.raw;
        }
      }
    },
    complete: () => {
      console.log('[readLoop] DONE');
    }
  });

  onDestroy(() => {
    unsubscribeMessage.unsubscribe();
  });
</script>

<div style="position: relative">
  <div style="display: flex; justify-content: center;">
    <button class="btn btn-outline-primary" on:click={calibrateStart}>{$t('calibration.calibrateBtn')} {$t('pedals.clutch')}</button>
  </div>
  <CalibrationOverlay show={calibrationInProgress}>
    <p style="text-align: center; font-size: 18px; margin: 5px;">
      {$t('calibration.calibrate.stepOne.one')}{$t('pedals.clutch')}{$t('calibration.calibrate.stepOne.two')}
    </p>
    <p style="text-align: center; font-size: 18px; margin: 5px;">
      {$t('calibration.calibrate.stepTwo.one')}{$t('pedals.clutch')}{$t('calibration.calibrate.stepTwo.two')}
    </p>
    <p style="text-align: center; font-size: 18px; margin: 5px;">{$t('calibration.calibrate.stepThree')}</p>

    <div style=" text-align: center; font-size: 18px; margin:20px">
      <button class="btn btn-success" on:click={calibrateDone}>{$t('calibration.doneBtn')}</button>
    </div>

    <!-- <p style="text-align: center;">
      <span>{$t('calibration.start')} <strong>{calibrationMapNumbers[0]}</strong></span>
      <span> | </span>
      <span>{$t('calibration.end')} <strong>{calibrationMapNumbers[1]}</strong></span>
    </p> -->
  </CalibrationOverlay>

  <slot />

  <div style="display:flex; justify-content:center">
    <div style="display: flex; justify-content: space-between; width:1500px;">
      <div style="display: flex; ">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"
              >{$t('calibration.start')}
              {$t('calibration.deadzone')}</span
            >
          </div>

          <input
            min="0"
            max="1023"
            type="number"
            class="form-control"
            on:input={(e) => updateContext(e)}
            name="2"
            value={calibrationMapNumbers[2]}
            style="text-align: center; width:120px;"
          />
        </div>
      </div>
      <div style="display: flex; justify-content:right;">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"
              >{$t('calibration.end')}
              {$t('calibration.deadzone')}</span
            >
          </div>
          <input
            min="0"
            max="1023"
            type="number"
            class="form-control"
            on:input={(e) => updateContext(e)}
            name="3"
            value={calibrationMapNumbers[3]}
            style="text-align: center; width:120px;"
          />
        </div>
      </div>
    </div>
  </div>
</div>
