<script>
  import { t, locale, locales } from '../i18n';
  import { getContext } from 'svelte';

  let connected = getContext('WSC-connected');
  let { connect, disconnect, write } = getContext('WSC-actions');

  const handleConnect = () => {
    connect();
  };
  const handleDisconnect = () => {
    disconnect();
  };
</script>

<div style="padding: 10px; display:flex; justify-content:center;">
  <button class="btn btn-sm btn-outline-success" disabled={$connected} on:click={handleConnect}>{$t('overlay.btnConnect')}</button>
  <!--<button disabled={!$connected} on:click={handleDisconnect}>disconnect</button>-->
</div>
