<script>
  import { getContext } from 'svelte';
  import { t } from '../i18n';
  let connected = getContext('WSC-connected');
  let { connect, disconnect, write } = getContext('WSC-actions');

  let pedalMapSerial = getContext('WSC-pedalMapSerial');
  let pedalMap = '';

  let calibrationMapSerial = getContext('WSC-calibrationMapSerial');
  let calibrationMap = '';

  let invertedMapSerial = getContext('WSC-invertedMapSerial');
  let invertedMap = '';

  let smoothMapSerial = getContext('WSC-smoothMapSerial');
  let smoothMap = '';

  let bitsMapSerial = getContext('WSC-bitsMapSerial');
  let bitsMap = '';

  let showModal = false;
  let fileName = '';

  const handleConnect = () => {
    connect();
  };
  const handleDisconnect = () => {
    disconnect();
  };

  pedalMapSerial.subscribe((value) => {
    pedalMap = value;
  });
  calibrationMapSerial.subscribe((value) => {
    calibrationMap = value;
  });
  invertedMapSerial.subscribe((value) => {
    invertedMap = value;
  });
  smoothMapSerial.subscribe((value) => {
    smoothMap = value;
  });
  bitsMapSerial.subscribe((value) => {
    bitsMap = value;
  });

  const exportToFile = () => {
    const data = [
      pedalMap.split(',')[0], //tmap
      pedalMap.split(',')[1], //bmap
      pedalMap.split(',')[2], //cmap
      calibrationMap, //tcali + bcali + ccali
      invertedMap,
      smoothMap,
      bitsMap
    ].join('\n');

    const blob = new Blob([data], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.ksr`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const saveToArduino = () => {
    write(pedalMap.split(',')[0]); //tmap
    write(pedalMap.split(',')[1]); //bmap
    write(pedalMap.split(',')[2]); //cmap
    write(calibrationMap); //tcali + bcali + ccali
    write(invertedMap);
    write(smoothMap);
    write(bitsMap);
  };

  const handleExport = () => {
    exportToFile();
    showModal = false;
  };

  const handleImport = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const text = await file.text();
      const [tmap, bmap, cmap, calibration, inverted, smooth, bits] = text.split('\n');
      pedalMap = `${tmap},${bmap},${cmap}`;
      calibrationMap = calibration;
      invertedMap = inverted;
      smoothMap = smooth;
      bitsMap = bits;
      saveToArduino();
      write('GetMap');
      write('GetCali');
      write('GetInverted');
      write('GetSmooth');
      write('GetBits');
    }
  };

  const getDefaultFileName = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}`;
  };

  const openExportModal = () => {
    fileName = getDefaultFileName();
    showModal = true;
  };
</script>

<button class="btn btn-success" disabled={!$connected} on:click={saveToArduino}>{$t('saveBtn')}</button>
<button class="btn btn-primary" on:click={openExportModal}>{$t('exportBtn')}</button>
<input type="file" accept=".ksr" on:change={handleImport} id="importFile" class="hidden" />
<label for="importFile" class="btn btn-secondary">{$t('importBtn')}</label>

{#if showModal}
  <div class="modal">
    <div class="modal-content">
      <span class="close" on:click={() => showModal = false}>&times;</span>
      <h2>Exportar</h2>
      <div class="form-group">
        <label for="fileName">Nombre del archivo:</label>
        <input type="text" id="fileName" bind:value={fileName} />
      </div>
      <button class="btn btn-primary" on:click={handleExport}>Exportar</button>
    </div>
  </div>
{/if}

<style>
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }

  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    max-width: 500px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .hidden {
    display: none;
  }
</style>
