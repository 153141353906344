export default {
  Español: {
    clutch: 'Embrague',
    brake: 'Freno',
    throttle: 'Acelerador',
    cancelBtn: 'Cancelar',
    saveBtn: 'Aplicar',
    exportBtn: 'Exportar',
    importBtn: 'Importar',
    'connectOverlay.btnConnect': 'CONECTAR PEDALERA',
    'connectOverlay.message':
      'Tu pedalera no está <u>conectada</u>! <br />Por favor conecta a tu pedalera<br /><br />Cuando hagas cambios en tu calibración no olvides <u>aplicarlos</u> <br /> <br />',
    'tabs.configuration': 'Configuración',
    'tabs.calibration': 'Calibración',
    'configuration.curves.customCurve': 'Curva personalizada',
    'configuration.curves.linear': 'Lineal',
    'configuration.curves.slowCurve': 'Curva lenta',
    'configuration.curves.verySlowCurve': 'Curva muy lenta',
    'configuration.curves.fastCurve': 'Curva rápida',
    'configuration.curves.veryFastCurve': 'Curva muy rápida',
    'configuration.curves.sCurveFastSlow': 'Curva en S rapida-lenta',
    'configuration.curves.sCurveSlowFast': 'Curva en S lenta-rapida',
    'configuration.check.smooth': 'Suavizado',
    'configuration.check.inverted': 'Invertido',
    'calibration.calibrateBtn': 'Calibrar',
    'calibration.doneBtn': 'Listo',
    'calibration.resetCalibrationBtn': 'Resetear calibración',
    'calibration.start': 'Valor Inicial',
    'calibration.end': 'Valor Final',
    'calibration.min': 'Zona Muerta Inferior',
    'calibration.max': 'Zona Muerta Superior',
    'calibration.calibrate.stepOne.one': 'Paso 1. Asegurate que el ',
    'calibration.calibrate.stepOne.two': ' no este presionado.',
    'calibration.calibrate.stepTwo.one': 'Paso 2. Presioná el ',
    'calibration.calibrate.stepTwo.two': ' a fondo y soltalo.',
    'calibration.calibrate.stepThree': 'Paso 3. Presioná "Listo" para terminar la calibración'
  },
  Ingles: {
    clutch: 'Clutch',
    brake: 'Brake',
    throttle: 'Throttle',
    cancelBtn: 'Cancel',
    saveBtn: 'Apply',
    exportBtn: 'Export',
    importBtn: 'Import',
    'connectOverlay.btnConnect': 'CONNECT',
    'connectOverlay.message':
      'Your pedals is not <u>connected</u>! <br />Please <u>connect</u> to your pedals, <br /><br />When you make changes or calibrate the pedals, dont forget to <u>save</u> <br /><br />',
    'tabs.configuration': 'Configuration',
    'tabs.calibration': 'Calibration',
    'configuration.curves.customCurve': 'Custom curve',
    'configuration.curves.linear': 'Linear',
    'configuration.curves.slowCurve': 'Slow curve',
    'configuration.curves.verySlowCurve': 'Very slow curve',
    'configuration.curves.fastCurve': 'Fast curve',
    'configuration.curves.veryFastCurve': 'Very fast curve',
    'configuration.curves.sCurveFastSlow': 'S curve fast-slow',
    'configuration.curves.sCurveSlowFast': 'S curve slow-fast',
    'pedals.check.smooth': 'Smooth',
    'pedals.check.inverted': 'Inverted',
    'calibration.calibrateBtn': 'Calibrate',
    'calibration.doneBtn': 'Done',
    'calibration.resetCalibrationBtn': 'Reset calibration',
    'calibration.start': 'Initial Value',
    'calibration.end': 'Final Value',
    'calibration.min': 'Minimum Deadzone',
    'calibration.max': 'Maximum Deadzone',
    'calibration.calibrate.stepOne.one': 'Step 1. Make sure the ',
    'calibration.calibrate.stepOne.two': ' is in neutral position.',
    'calibration.calibrate.stepTwo.one': 'Step 2. Press the ',
    'calibration.calibrate.stepTwo.two': ' all the way down and then release.',
    'calibration.calibrate.stepThree': 'Step 3. Press done to finish calibration'
  }
};
