<p>
    Thank you for using this open source solution for diy pedals. <br>
    This is not to be sold its open and free for everyone to use. <br>
<p/>
<p>
    You can find executable and arduino code in the following locations <br>
    Gui executable: <a href="https://github.com/vospascal/pedal-gui">pedal-gui</a> <br>
    Arduino code that works with the gui exe file: <a
        href="https://github.com/vospascal/pedal-arduino/">pedal-arduino</a> <br>
<p/>
<p>
    If you like it please consider a donation to further development. <br>
    <a href="paypal.com/donate/?business=TBPE6XCB2XBMW&item_name=pedalbox&currency_code=EUR">donate on
        paypal</a>
</p>