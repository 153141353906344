<div class="tab-list" style="margin-bottom: 40px;">
  <slot />
</div>

<style>
  .tab-list {
    display: flex;
    justify-content: center;
  }
</style>
