export default {
  Español: {
    'overlay.btnConnect': 'CONECTAR PEDALERA',
    'overlay.message':
      'Tu pedalera no está <u>conectada</u>! <br />Por favor conecta a tu pedalera<br /><br />Cuando hagas cambios en tu calibración no olvides <u>guardarlos</u> <br /> <br />',
    'tabs.pedals': 'Pedales',
    'tabs.calibration': 'Calibración',
    'pedals.oldMapBtn': 'Recargar Configuación Actual',
    'pedals.clutch': 'Embrague',
    'pedals.brake': 'Freno',
    'pedals.throttle': 'Acelerador',
    'pedals.curves.customCurve': 'Curva personalizada',
    'pedals.curves.linear': 'Lineal',
    'pedals.curves.slowCurve': 'Curva lenta',
    'pedals.curves.verySlowCurve': 'Curva muy lenta',
    'pedals.curves.fastCurve': 'Curva rápida',
    'pedals.curves.veryFastCurve': 'Curva muy rápida',
    'pedals.curves.sCurveFastSlow': 'Curva en S rapida-lenta',
    'pedals.curves.sCurveSlowFast': 'Curva en S lenta-rapida',
    'pedals.check.smooth': 'Suavizado',
    'pedals.check.inverted': 'Invertido',
    'calibration.calibrateBtn': 'Calibrar',
    'calibration.doneBtn': 'Listo',
    'calibration.oldCalibrationBtn': 'Recargar Configuación Actual',
    'calibration.resetCalibrationBtn': 'Resetear calibración',
    'calibration.deadzone': 'Zona Muerta',
    'calibration.start': 'Inicio de',
    'calibration.end': 'Fin de',
    'calibration.calibrate.stepOne.one': 'Paso 1. Asegurate que el ',
    'calibration.calibrate.stepOne.two': ' no este presionado.',
    'calibration.calibrate.stepTwo.one': 'Paso 2. Presioná el ',
    'calibration.calibrate.stepTwo.two': ' a fondo y soltalo.',
    'calibration.calibrate.stepThree': 'Paso 3. Presioná "Listo" para terminar la calibración',
    saveBtn: 'Guardar'
  },
  Ingles: {
    'overlay.btnConnect': 'CONNECT',
    'overlay.message':
      'Your pedals is not <u>connected</u>! <br />Please <u>connect</u> to your pedals, <br /><br />When you make changes or calibrate the pedals, dont forget to <u>save</u> <br /><br />',
    'tabs.pedals': 'Pedals',
    'tabs.calibration': 'Calibration',
    'pedals.oldMapBtn': 'Get old map',
    'pedals.clutch': 'Clutch',
    'pedals.brake': 'Brake',
    'pedals.throttle': 'Throttle',
    'pedals.curves.customCurve': 'Custom curve',
    'pedals.curves.linear': 'Linear',
    'pedals.curves.slowCurve': 'Slow curve',
    'pedals.curves.verySlowCurve': 'Very slow curve',
    'pedals.curves.fastCurve': 'Fast curve',
    'pedals.curves.veryFastCurve': 'Very fast curve',
    'pedals.curves.sCurveFastSlow': 'S curve fast-slow',
    'pedals.curves.sCurveSlowFast': 'S curve slow-fast',
    'pedals.check.smooth': 'Smooth',
    'pedals.check.inverted': 'Inverted',
    'calibration.calibrateBtn': 'Calibrate',
    'calibration.doneBtn': 'Done',
    'calibration.oldCalibrationBtn': 'Old calibration',
    'calibration.resetCalibrationBtn': 'Reset calibration',
    'calibration.deadzone': 'Deadzone',
    'calibration.start': 'Start',
    'calibration.end': 'End',
    'calibration.calibrate.stepOne.one': 'Step 1. Make sure the ',
    'calibration.calibrate.stepOne.two': ' is in neutral position.',
    'calibration.calibrate.stepTwo.one': 'Step 2. Press the ',
    'calibration.calibrate.stepTwo.two': ' all the way down and then release.',
    'calibration.calibrate.stepThree': 'Step 3. Press done to finish calibration',
    saveBtn: 'Save'
  }
};
